<template>
    <section id="meet-our-talents" class="meetTalents innerPage" :class="{ 'meetTalents2 ': featureId4 }">
        <div class="container">
            <div class="tw-flex tw-items-center tw-justify-between">
                <h2 class="meetTalents__title" :class="{ 'meetTalents__title2': featureId4 }">
                    <span>{{ $t('home.meetOur') }}</span>
                    <span>{{ $t('home.talents') }}</span>
                </h2>
                <button class="viewMoreTalents tw-hidden lg:tw-flex" @click="handleRedirection('/client-signup')">
                    <span>
                        {{ $t('home.viewMoreTalents') }}
                    </span>
                    <svg viewBox="0 0 40 20" width="40px" height="20px">
                        <path fill="#422E87"
                            d="m39.542 8.858-.002-.001L31.376.47a1.528 1.528 0 0 0-2.21.005 1.65 1.65 0 0 0 .005 2.281l5.482 5.631H1.563C.7 8.387 0 9.11 0 10c0 .89.7 1.613 1.562 1.613h33.09l-5.48 5.63a1.65 1.65 0 0 0-.006 2.282 1.528 1.528 0 0 0 2.21.005l8.164-8.387.002-.001c.612-.63.61-1.655 0-2.284Z" />
                    </svg>
                </button>
            </div>
            <Carousel @handleSkillClick="handleRoleClick($event)" :skills="roles" :isResponse="!isLoading"
                :featureId4="featureId4" />
            <template v-if="talents && talents.length && !isLoading">

                <div v-if="featureId4" class=" tw-flex tw-gap-4 tw-snap-x tw-snap-proximity tw-overflow-x-auto">
                    <div v-for="talent in activeTalents" :key="talent.id">
                        <NewTalentCard class="tw-snap-center tw-min-h-[17rem]" :talent="talent" :isLoaded="isLoading"
                            @open-mini-profile="openProfileModal($event)" />

                    </div>
                </div>
                <div class="mb-5 row talents-card" v-else>
                    <div class="text-center col-md-6 col-lg-4" v-for="talent in activeTalents" :key="talent.id">

                        <TalentCard mini-profile :talent="talent" :isLoaded="isLoading"
                            @open-mini-profile="openProfileModal($event)" />
                    </div>
                </div>
            </template>

            <talentsSkeletons v-if="isLoading && !featureId4" />

            <div v-if="featureId4 && isLoading"
                class=" tw-flex tw-gap-4 tw-snap-x tw-snap-proximity tw-overflow-x-auto">
                <TalentCardSkeleton2 />
                <TalentCardSkeleton2 />
                <TalentCardSkeleton2 />
            </div>

            <button v-if="!isLoading" :class="featureId4 ? 'viewMoreTalents2 tw-mt-6 ' : 'viewMoreTalents tw-mt-12'"
                class=" tw-flex lg:tw-hidden" @click="handleRedirection('/client-signup')">
                <span v-if="featureId4">
                    {{ $t('home.viewMoreTalents2') }}
                </span>
                <span v-else>
                    {{ $t('home.viewMoreTalents') }}
                </span>
                <svg viewBox="0 0 40 20" width="40px" height="20px">
                    <path fill="#422E87"
                        d="m39.542 8.858-.002-.001L31.376.47a1.528 1.528 0 0 0-2.21.005 1.65 1.65 0 0 0 .005 2.281l5.482 5.631H1.563C.7 8.387 0 9.11 0 10c0 .89.7 1.613 1.562 1.613h33.09l-5.48 5.63a1.65 1.65 0 0 0-.006 2.282 1.528 1.528 0 0 0 2.21.005l8.164-8.387.002-.001c.612-.63.61-1.655 0-2.284Z" />
                </svg>
            </button>
            <div class="profileModal__wrapper">
                <transition name="slide">
                    <div v-if="isOpen" class="profileModal__wrapper__container">
                        <div class="profileModal__wrapper__header">
                            <h5>{{ $t('home.talentProfile') }}</h5>
                            <button class="profileModal__wrapper__close btn-close" @click="isOpen = false"></button>
                        </div>
                        <div class="profileModal__wrapper__profile">
                            <Profile mini :sharable-code="sharableCode" />
                        </div>
                    </div>
                </transition>
                <div v-if="isOpen" class="blur-wrapper" :class="{ active: isOpen }" @click="isOpen = false">
                </div>
            </div>
        </div>
    </section>

</template>

<script>
const Profile = () => import(/* webpackChunkName: "clientViewTalentProfile" */ "@/pages/Client/clientViewTalentProfile/newProfile.vue");
const TalentCard = () => import(/* webpackChunkName: "TalentCard" */ "@/pages/Client/matching/components/talentCard/talentCard.vue");
const NewTalentCard = () => import(/* webpackChunkName: "NewTalentCard" */ "@/pages/Client/matching/components/talentCard/newTalentCard.vue");
const Carousel = () => import(/* webpackChunkName: "clientCarousel" */ "@/pages/Client/matching/components/carousel/carousel.vue");
const talentsSkeletons = () => import(/* webpackChunkName: "talentSkeltons" */ "@/pages/Client/matching/components/talentSkeltons/talentSkeltons.vue");
const TalentCardSkeleton2 = () => import( /* webpackChunkName: "talentCardSkeleton2" */ "@/pages/Client/matching/components/talentCard/TalentCardSkeleton2.vue");
export default {
    components: { Profile, TalentCard, NewTalentCard, Carousel, talentsSkeletons, TalentCardSkeleton2 },
    data() {
        return {
            talents: null,
            isOpen: false,
            isLoading: false,
            sharableCode: null,
            currentRoleId: null,
            roles: [],
        };
    },
    props: {
        featureId4: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        activeTalents() {
            return this.talents.filter(talent => {
                return talent.role.id === this.currentRoleId;
            });
        },
    },
    watch: {
        isOpen(value) {
            const body = document.querySelector('body');
            if (value)
                body.classList.add('is-fixed');
            else
                body.classList.remove('is-fixed');
        },
    },
    async mounted() {
        this.isLoading = true;
        await this.axios
            .get(`/api/staticContent/featuredTalents`)
            .then((res) => {
                this.talents = res.data.data;
                const roleNames = [];
                this.talents.forEach(talent => {
                    if (!roleNames.includes(talent.role.name)) {
                        this.roles.push(talent.role);
                        roleNames.push(talent.role.name);
                    }
                })

                this.currentRoleId = this.roles[0]?.id;
                this.isLoading = false;
            });
    },
    methods: {
        handleRoleClick(id) {
            console.log(id);

            this.currentRoleId = id;
        },
        openProfileModal(sharableCode) {
            this.sharableCode = sharableCode;
            this.isOpen = true;
        },
        handleRedirection(path) {
            this.$router.push({ path });
        },
    }
}
</script>

<style lang="scss">
.meetTalents2 {
    background: linear-gradient(to right, #ededed, #ece8ff, #e3d3fb, #e1e1e1);
}

.innerPage.meetTalents2 {
    @media (max-width: 768px) {
        padding-bottom: 20px;
    }
}



.tw-snap-x {
    overflow-x: scroll;
    white-space: nowrap;
    margin: auto;
    scroll-snap-type: x mandatory;


}


.tw-snap-center {
    scroll-snap-align: center;
    scroll-margin-right: 1rem;
    scroll-margin-left: 1rem;
}


.meetTalents {
    &__title2 {
        font-size: 30px !important;
        width: 100%;
        text-align: center;


        @media (max-width: 768px) {
            margin-bottom: 10px !important;
        }
    }

    &__title {
        font-size: 45px;
        font-weight: 600 !important;
        margin-bottom: 44px;

        span:last-child {
            color: #ffc639;
        }

        @media (max-width: 768px) {
            font-size: 28px;
        }
    }
}

.profileModal__wrapper {
    position: relative;

    &__profile {
        max-width: 650px;
    }

    &__container {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 80%;
        max-width: 650px;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: white;
        transition: transform 0.15s;
        z-index: 100;
        overflow-y: scroll;
        overflow-x: hidden;
        border-radius: 20px 0 0 20px;
        padding: 0 0 30px;
    }

    &__header {
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: #fff;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 27px 20px 18px;
        border-bottom: 1px solid #e2e2e2;

        h5 {
            margin-inline-end: auto;
            font-size: 19px;
            font-weight: 600;
        }
    }

    &__close {
        border: 1px solid #e2e2e2;
        padding: 5px;
        color: #000;
        background-color: transparent;
        font-size: 1.1em;
    }

    >.blur-wrapper {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, .7);
        z-index: 99;
        opacity: 0;
        transition: opacity 1s ease;
    }

    >.blur-wrapper.active {
        opacity: 1;
    }
}


.slide-enter-active {
    transform: translate(0, 0) !important;
    -webkit-transform: translate(0, 0) !important;
    transform: translate(100%, 0) !important;
    -webkit-transform: translate(100%, 0) !important;
}

.slide-leave-active {
    transform: translate(100%, 0) !important;
    -webkit-transform: translate(100%, 0) !important;
}

html[dir="rtl"] {
    .profileModal__wrapper {
        &__container {
            right: auto;
            left: 0;
            border-radius: 0 30px 30px 0;
        }
    }

    .slide-enter-active {
        transform: translate(-100%, 0) !important;
        -webkit-transform: translate(-100%, 0) !important;
    }

    .slide-leave-active {
        transform: translate(-100%, 0) !important;
        -webkit-transform: translate(-100%, 0) !important;
    }
}

.viewMoreTalents {
    align-items: center;
    justify-content: center;
}

.viewMoreTalents2 {
    align-items: center;
    justify-content: center;
}

.viewMoreTalents span {
    padding-inline-end: 12px;
    font-size: 20px;
    font-weight: 700;
    color: #422e87;
}

.viewMoreTalents2 span {
    padding-inline-end: 12px;
    font-size: 18px;
    font-weight: 700;
    color: #422e87;
}


html[dir="rtl"] {
    .viewMoreTalents svg {
        transform: rotate(180deg);
    }
}

html[dir="rtl"] {
    .viewMoreTalents2 svg {
        transform: rotate(180deg);
    }
}
</style>